import React from 'react';
import ServicesSlider from './ServicesSlider/ServicesSlider';
import SectionHeader from './SectionHeader/SectionHeader';

const AboutServices = ({ sectionTitle, dataServices }) => {
  return (
    <section className="about-services">
      <div className="container-fluid">
        <SectionHeader sectionTitle={sectionTitle} />
        <ServicesSlider dataServices={dataServices} />
      </div>
    </section>
  );
};

export default AboutServices;
