import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';

import DecorLeft from '../../../../svg/decor_left.svg';
import DecorRight from '../../../../svg/decor_right.svg';

const AboutMission = ({ dataMission }) => {
  const langToggle = useLanguage;

  return (
    <section className="about-mission">
      <div className="section-decor">
        <DecorLeft className="decor-left" />
        <DecorRight className="decor-right" />
      </div>
      <div className="container-fluid">
        <div className="about-mission__content">
          <h2 className="title-section">
            {langToggle(
              dataMission.mission_title_ua,
              dataMission.mission_title_ru,
              dataMission.mission_title_en
            )}
          </h2>
          <p className="text-section">
            {langToggle(
              dataMission.mission_description_ua,
              dataMission.mission_description_ru,
              dataMission.mission_description_en
            )}
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutMission;
