import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../../Hooks/useLanguage';

const Slide = ({ slide }) => {
  const langToggle = useLanguage;
  return (
    <div className="services-slider__slide">
      <div className="slide-wrapper">
        <div className="slide-background"></div>
        <img className="slide-icon" 
          src={`https://admin.oxx-clinic.com.ua${slide.icon.url}`}  
          alt={langToggle(slide.title_ua, slide.title_ru, slide.title_en)} 
        />
        <div className="slide-content">
          <span className="slide-content__title hover-fade">
            {langToggle(slide.title_ua, slide.title_ru, slide.title_en)}
          </span>
          <span className="slide-content__title hover-fade">
            {langToggle('Дізнатись більше', 'Узнать больше', 'Learn more')}
          </span>
        </div>
        <Link to={'/' + slide.slug} className="slide-content__link"></Link>
      </div>
    </div>
  );
};

export default Slide;
