import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import MainScreenSecond from '../components/UI/MainScreenSecond/MainScreenSecond';
import Instagram from '../components/Layout/Instagram/Instagram';
import AboutHistory from '../components/Pages/AboutPage/AboutHistory/AboutHistory';
import AboutFounders from '../components/Pages/AboutPage/AboutFounders/AboutFounders';
import AboutMission from '../components/Pages/AboutPage/AboutMission/AboutMission';
import HomeQuote from '../components/Pages/Home/HomeQuote/HomeQuote';
import HomeGallery from '../components/Pages/Home/HomeGallery/HomeGallery';
import AboutVideo from '../components/Pages/AboutPage/AboutVideo/AboutVideo';
import AboutValues from '../components/Pages/AboutPage/AboutValues/AboutValues';

import dataAbout from '../db/dataAbout';
// import dataServices from '../db/dataServices';
import AboutServices from '../components/Pages/AboutPage/AboutServices/AboutServices';

const AboutPage = ({data}) => {
  const { t } = useTranslation();

  const aboutDb = dataAbout;
  // const servicesDb = dataServices;

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Про Клініку',
      title_ru: 'О Клинике',
      title_en: 'About Clinic',
      to: '/about/',
    },
  ];


  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('Home')} />
      <MainScreenSecond
        mainData={data.strapiAbout}
        crumbsNav={crumbsNav}
      />
      <AboutHistory datahHistory={data.strapiAbout} />
      <AboutFounders dataFounders={data.strapiAbout.About_doctors} />
      <AboutMission dataMission={data.strapiAbout} />
      <AboutVideo dataPartners={data.strapiAbout} />
      <AboutValues dataValues={data.strapiHome.values_title} />
      <AboutServices
        dataServices={data.allStrapiServicesCategories.nodes}
        sectionTitle={aboutDb.about_services}
      />
      <HomeGallery dataGallery={data.strapiHome.gaellery_title} />
      <HomeQuote dataQuote={data.strapiHome.quotation} />
      <Instagram />
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query About($language: String!) {
    strapiAbout {
      title_ua
      title_ru
      title_en
      image {
        localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
          }
      }
      story_title_ua
      story_title_ru
      story_title_en
      story_description_ua
      story_description_ru
      story_description_en
      About_doctors {
        section_title_ua
        section_title_ru
        section_title_en
        title_ua
        title_ru
        title_en
        description_ua
        description_ru
        description_en
        link
        image {
          localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }
      }
      mission_title_ua
      mission_title_ru
      mission_title_en
      mission_description_ua
      mission_description_ru
      mission_description_en
      video_link
      image_cover {
        localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
        }
    }
    allStrapiServicesCategories {
      nodes {
        title_ua
        title_ru
        title_en
        slug
        icon {
          url
        }
      }
    }
    strapiHome {
      values_title {
        section_title_ua
        section_title_ru
        section_title_en
        title {
          title_ua
          title_ru
          title_en
        }
        Values {
          title_ua
          title_ru
          title_en
          values_ua
          values_ru
          values_en
        }
      }
      partners {
        section_title_ua
        section_title_ru
        section_title_en
        title_ua
        title_ru
        title_en
        video_link
        image_cover {
          localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
        }
      }
      gaellery_title {
        section_title_ua
        section_title_ru
        section_title_en
        title {
          title_ua
          title_ru
          title_en
        }
        gallery {
          image {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
         }
      }
      quotation {
        title_ua
        title_ru
        title_en
        quotation_ua
        quotation_ru
        quotation_en
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }

`