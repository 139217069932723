import React from 'react';

import useLanguage from '../../../Hooks/useLanguage';
import ImageCarousel from '../../../UI/ImageCarousel/ImageCarousel';

const HomeGallery = ({ dataGallery }) => {
  const langToggle = useLanguage;

  return (
    <section className="home-gallery" data-scroll-section>
      <div className="home-gallery__header">
        <span className="section-title">
          {langToggle(
            dataGallery.section_title_ua,
            dataGallery.section_title_ru,
            dataGallery.section_title_en
          )}
        </span>
        <h2 className="section-description">
          {langToggle(
            dataGallery.title.title_ua,
            dataGallery.title.title_ru,
            dataGallery.title.title_en
          )}
        </h2>
      </div>
      <ImageCarousel dataGallery={dataGallery} />
    </section>
  );
};

export default HomeGallery;
