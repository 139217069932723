import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useLanguage from '../../../Hooks/useLanguage';
import ButtonLink from '../../../UI/ButtonLink';

const AboutFounders = ({ dataFounders }) => {
  const langToggle = useLanguage;

  return (
    <section className="founders">
      {dataFounders.map((item, index) => {
        
        const image = getImage(item.image.localFile);

        return (
          <div className="founders-item" key={index}>
            <div className="founders-item__image">
              <GatsbyImage
                image={image}
                className="founder-img"
                alt={langToggle(
                  item.section_title_ua,
                  item.section_title_ru,
                  item.section_title_en
                )}
              />
            </div>
            <div className="founders-item__content">
              <span className="section-title">
                {langToggle(
                  item.section_title_ua,
                  item.section_title_ru,
                  item.section_title_en
                )}
              </span>
              <h2 className="section-description">
                {langToggle(item.title_ua, item.title_ru, item.title_en)}
              </h2>
              <p className="section-text">
                {langToggle(item.description_ua, item.description_ru, item.description_en)}
              </p> 
              <ButtonLink
                to={item.link}
                title={langToggle(
                  'Дізнатись Більше',
                  'Узнать больше',
                  'Learn More'
                )}
              /> 
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default AboutFounders;
