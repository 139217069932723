import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import ArrowRight from '../../svg/arrow_right.svg';

const ButtonLink = ({ title, to }) => (
  <Link to={to} className="button-default button-default_link">
    <span className="button-default__wrapper">
      <span className="title">{title}</span>
      <span className="button-default__icons">
        <span className="icons-wrapper">
          <i className="button-icon">
            <ArrowRight />
          </i>
          <i className="button-icon">
            <ArrowRight />
          </i>
        </span>
      </span>
    </span>
  </Link>
);

export default ButtonLink;
