const dataAbout = {
  history: {
    title_ua: 'Наша iсторія',
    title_ru: 'Наша история',
    title_en: 'Our history',
    history_ua:
      "Основною місією клініки є - відновлення молодості і здоров'я Вашого організму. Реалізуючи свою місію вже більше 9 років, ми індивідуально і максимально делікатно підходимо до вирішення проблеми кожного пацієнта, підбираючи комплексні програми, в яких об'єднані переваги різних сучасних методик.",
    history_ru:
      'Главная миссия клиники - восстановить молодость и здоровье вашего организма. Реализуя свою миссию более 9 лет, мы индивидуально и деликатно подходим к решению проблемы каждого пациента, выбирая комплексные программы, сочетающие преимущества различных современных методик.',
    history_en:
      "The main mission of the clinic is to restore the youth and health of your body. Realizing our mission for more than 9 years, we individually and delicately approach the solution of each patient's problem, choosing comprehensive programs that combine the advantages of various modern techniques.",
  },
  founders: [
    {
      id: 1,
      description_ua: 'Душа клінікі',
      description_ru: 'Душа клиники',
      description_en: 'Soul of the clinic',
      title_ua: 'Оксана Кисса',
      title_ru: 'Оксана Кысса',
      title_en: 'Oksana Kyssa',
      content_ua:
        'Лікар дерматолог-венеролог, лікар естетичної медицини, Сертифікований тренер з навчання фахівців методиками введення ботулотоксином (Диспорт, Іпсен), філерів (Теосіал (Теоксан), рестилайн (Галдерма), коллагеностімуляторов (скульптра (Галдерма). Більше ніж 50 000 маніпуляцій за 25 років, тисячі вдячних пацієнтів.',
      content_ru:
        'Врач дерматолог-венеролог, Врач эстетической медицины, Сертифицированный тренер по обучению специалистов методикам введения ботулотоксинов (Диспорт, Ипсен), филлеров (Теосиал (Теоксан), рестилайн (Галдерма), коллагеностимуляторов (скульптра (Галдерма). Более 50 000 манипуляций за 25 лет, тысячи благодарных пациентов.',
      content_en:
        'Dermatologist-venereologist, Doctor of aesthetic medicine, Certified trainer for training specialists in methods of introducing botulinum toxins (Dysport, Ipsen), fillers (Teosial (Teoxan), Restylane (Galderma), collagen stimulants (sculptra (Galderma)). More than 50,000 manipulations in 25 years, thousands of grateful patients.',
      link: '/founder/',
    },
    {
      id: 2,
      description_ua: 'Головний лікар',
      description_ru: 'Главный врач',
      description_en: 'Chief physician',
      title_ua: 'Сергій Кисса',
      title_ru: 'Сергей Кысса',
      title_en: 'Sergei Kyssa',
      content_ua:
        'Лікар дерматолог-венеролог, лікар естетичної медицини, Сертифікований тренер з навчання фахівців методиками введення ботулотоксином (Диспорт, Іпсен), філерів (Теосіал (Теоксан), рестилайн (Галдерма), коллагеностімуляторов (скульптра (Галдерма). Більше ніж 50 000 маніпуляцій за 25 років, тисячі вдячних пацієнтів.',
      content_ru:
        'Врач дерматолог-венеролог, Врач эстетической медицины, Сертифицированный тренер по обучению специалистов методикам введения ботулотоксинов (Диспорт, Ипсен), филлеров (Теосиал (Теоксан), рестилайн (Галдерма), коллагеностимуляторов (скульптра (Галдерма). Более 50 000 манипуляций за 25 лет, тысячи благодарных пациентов.',
      content_en:
        'Dermatologist-venereologist, Doctor of aesthetic medicine, Certified trainer for training specialists in methods of introducing botulinum toxins (Dysport, Ipsen), fillers (Teosial (Teoxan), Restylane (Galderma), collagen stimulants (sculptra (Galderma)). More than 50,000 manipulations in 25 years, thousands of grateful patients.',
      link: '/founder/',
    },
  ],
  mission: {
    title_ua: 'Наша Місія',
    title_ru: 'Наша Миссия',
    title_en: 'Our Mission',
    mission_ua:
      "Основною місією клініки є - відновлення молодості і здоров'я Вашого організму. Реалізуючи свою місію вже більше 9 років, ми індивідуально і максимально делікатно підходимо до вирішення проблеми кожного пацієнта, підбираючи комплексні програми, в яких об'єднані переваги різних сучасних методик.",
    mission_ru:
      'Главная миссия клиники - восстановить молодость и здоровье вашего организма. Реализуя свою миссию более 9 лет, мы индивидуально и деликатно подходим к решению проблемы каждого пациента, выбирая комплексные программы, сочетающие преимущества различных современных методик.',
    mission_en:
      "The main mission of the clinic is to restore the youth and health of your body. Realizing our mission for more than 9 years, we individually and delicately approach the solution of each patient's problem, choosing comprehensive programs that combine the advantages of various modern techniques.",
  },
  values_title: {
    title_ua: 'O.X.X. Clinic - Мистецтво Природної Краси',
    title_ru: 'O.X.X. Clinic - Искусство Естественной Красоты',
    title_en: 'O.X.X. Clinic - The Art of Natural Beauty',
    title: {
      title_ua: 'Наші цінності',
      title_ru: 'Наши ценности',
      title_en: 'Our values',
    },
    values: [
      {
        title_ua: 'Природність',
        title_ru: 'Естественность',
        title_en: 'Naturalness',
        values_ua:
          'Будь-яку нашу роботу неможливо відрізнити від природної краси. Ми не робимо особливо небезпечних коригувань. Ви ніколи не помітите наслідків процедури.',
        values_ru:
          'Любая наша работа неотличима от естественной природной красоты. Мы не делаем нарочитых неестественных коррекций. Вы никогда не заметите следов процедуры. ',
        values_en:
          'Any of our work is indistinguishable from natural beauty. We do not make particularly dangerous adjustments. You will never notice the consequences of the procedure.',
      },
      {
        title_ua: 'Гарний Смак',
        title_ru: 'Хорошый вкус',
        title_en: 'Good taste',
        values_ua:
          'Мы всегда сделаем то, что максимально к лицу именно вам. То, что будет гармоничным именно для вашего уникального образа. Мы не делаем губы «как у Кайли» или скулы «под Дженифер».',
        values_ru:
          'Ми завжди зробимо те, що максимально на-віч саме вам. Те, що буде гармонійним саме для вашого унікального образу. Ми не робимо губи «як у Кайлі» або вилиці «під Дженіфер».',
        values_en:
          "We will always do what suits you best. What will be harmonious for your unique look. We don't do Kylie's lips or Jennifer's cheekbones.",
      },
      {
        title_ua: 'Любов і Прийняття',
        title_ru: 'Любовь и принятие',
        title_en: 'Love and Acceptance',
        values_ua:
          'Наші процедури допомагають жінці прийняти своє тіло, краще зрозуміти його та полюбити. Ми піклуємося про неї та бережимо її, допомагаючи їй побачити та усвідомити її природну красу.',
        values_ru:
          'Наши процедуры помогают женщине принять своё тело, понять его лучше и полюбить. Мы заботимся о ней и лелеем её, помогая ей увидеть и осознать свою естественную красоту. ',
        values_en:
          'Our procedures help a woman accept her body, understand it better and love it. We take care of her and cherish her, helping her to see and realize her natural beauty.',
      },
    ],
  },
  about_services: {
    title_ua: 'Послуги клінікі',
    title_ru: 'Услуги клиники',
    title_en: 'Clinic services',
    decription_ua: 'Процедури, послуги та напрямки клініки',
    decription_ru: 'Процедуры, кслуги и направления клиники',
    decription_en: 'Procedures, services and strands of the clinic',
  },
  gaellery_title: {
    title: {
      title_ua: 'Фото клініки',
      title_ru: 'Фото клиники',
      title_en: 'Clinic photos',
    },
    title_ua: 'Затишок та комфорт в кожному елементі шнтер’єру',
    title_ru: 'Уют и комфорт в каждом элементe интерьера',
    title_en: 'Coziness and comfort in every element Of The Interior',
  },
  quotation: {
    quotation_en:
      'Making a person not only beautiful, but also self-confident, and therefore happy - is a professional mission.',
    quotation_ru:
      'Сделать человека не только красивым, а и уверенным в себе, а значит счастливым - это профессиональная миссия.',
    quotation_ua:
      'Зробити людину не тільки красивою, а і впевненою в собі, а значить щасливою - це професійна місія.',
    title_ua: 'Оксана Кисса',
    title_ru: 'Оксана Кысса',
    title_en: 'Oksana Kyssa',
  },
};

export default dataAbout;
