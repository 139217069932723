import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ArrowLeft from '../../../svg/arrow_left.svg';
import ArrowRight from '../../../svg/arrow_right.svg';

const ImageCarousel = ({dataGallery}) => {


  // const slides = [
  //   { id: 1, content: '01' },
  //   { id: 2, content: '02' },
  //   { id: 3, content: '03' },
  //   { id: 4, content: '04' },
  //   { id: 5, content: '05' },
  //   { id: 6, content: '06' },
  //   { id: 7, content: '07' },
  //   { id: 8, content: '08' },
  // ];

  const [currentIndex, setCurrentIndex] = React.useState(1);

  function prevSlide() {
    setCurrentIndex(currentIndex - 1);
  }

  function nextSlide() {
    setCurrentIndex(currentIndex + 1);
  }

  return (
    <div className="carousel-wrapper">
      <div className="carousel">
        {(dataGallery.gallery || []).map((slide, index) => {
          
          const image = getImage(slide.image.localFile);

          const slideClasses = {
            [currentIndex - 2]: 'carousel-slide--previous',
            [currentIndex - 1]: 'carousel-slide--left',
            [currentIndex]: 'carousel-slide--active',
            [currentIndex + 1]: 'carousel-slide--right',
            [currentIndex + 2]: 'carousel-slide--next',
          };

          if (index < currentIndex - 2 || index > currentIndex + 2) {
            return null;
          }
          return (
            <div
              className={`carousel-slide ${slideClasses[index]}`}
              key={index}
              onClick={() => setCurrentIndex(index)}
              role="presentation"
            >
              <GatsbyImage
                className="carousel-image"
                image={image}
                alt="gallery"
              />
            </div>
          );
        })}
      </div>
      <div className="carousel-navigation">
        <div className="button-wrapper__left">
          <button
            className="carousel-button carousel-button__prev"
            disabled={currentIndex === 0}
            onClick={prevSlide}
          >
            Prev
            <ArrowLeft />
          </button>
        </div>
        <div className="button-wrapper__right">
          <button
            className="carousel-button carousel-button__next"
            disabled={currentIndex === dataGallery?.gallery?.length - 1}
            onClick={nextSlide}
          >
            Next
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
