import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const AboutValuesHeader = ({ dataValues }) => {
  const langToggle = useLanguage;
  return (
    <div className="values-section__header">
      <span className="section-title">
        {langToggle(
          dataValues.section_title_ua,
          dataValues.section_title_ru,
          dataValues.section_title_en
        )}
      </span>
      <h2 className="section-description">
        {langToggle(
          dataValues.title.title_ua,
          dataValues.title.title_ru,
          dataValues.title.title_en
        )}
      </h2>
    </div>
  );
};

export default AboutValuesHeader;
