import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import DecorLeft from '../../../../svg/decor_left.svg';
import DecorRight from '../../../../svg/decor_right.svg';
import Quotes from '../../../../svg/quotes.svg';

const HomeQuote = ({ dataQuote }) => {
  const langToggle = useLanguage;

  return (
    <section className="home-quote" data-scroll-section>
      <div className="section-decor">
        <DecorLeft className="decor-left" />
        <DecorRight className="decor-right" />
      </div>
      <div className="container-fluid">
        <div className="quote-content">
          <i className="qute-icon">
            <Quotes />
          </i>
          <p className="quote-text">
            {langToggle(
              dataQuote.quotation_ua,
              dataQuote.quotation_ru,
              dataQuote.quotation_en
            )}
          </p>
          <span className="quote-autograph">
            {langToggle(
              dataQuote.title_ua,
              dataQuote.title_ru,
              dataQuote.title_en
            )}
          </span>
        </div>
      </div>
    </section>
  );
};

export default HomeQuote;
