import React from 'react';
import AboutValuesContent from './AboutValuesContent/AboutValuesContent';
import AboutValuesHeader from './AboutValuesHeader/AboutValuesHeader';

const AboutValues = ({ dataValues }) => {
  return (
    <section className="about-values">
      <div className="container-fluid">
        <AboutValuesHeader dataValues={dataValues} />
        <AboutValuesContent dataValues={dataValues} />
      </div>
    </section>
  );
};

export default AboutValues;
