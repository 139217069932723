import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';

import ArrowLeft from '../../../../../svg/arrow_left.svg';
import ArrowRight from '../../../../../svg/arrow_right.svg';
import Slide from './Slide';

const ServicesSlider = ({ dataServices }) => {
  const slider = useRef(null);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <button className="slider-button slider-button__next" onClick={onClick}>
        Next
        <ArrowRight />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="slider-button slider-button__prev" onClick={onClick}>
        Prev
        <ArrowLeft />
      </button>
    );
  }

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    //autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
    ],
  };

  return (
    <Slider ref={slider} {...settings} className="services-slider">
      {dataServices.map((slide, index) => {
        return <Slide key={index} slide={slide} />;
      })}
    </Slider>
  );
};

export default ServicesSlider;
