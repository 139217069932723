import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const SectionHeader = ({ sectionTitle }) => {
  const langToggle = useLanguage;

  return (
    <div className="section-header">
      <span className="section-title">
        {langToggle(
          sectionTitle.title_ua,
          sectionTitle.title_ru,
          sectionTitle.title_en
        )}
      </span>
      <h2 className="section-description">
        {langToggle(
          sectionTitle.decription_ua,
          sectionTitle.decription_ru,
          sectionTitle.decription_en
        )}
      </h2>
    </div>
  );
};

export default SectionHeader;
